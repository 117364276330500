main {
  max-width: calc(100% - 322px);
  margin-left: auto;
  height: 100vh;
  padding: 32px 40px 17px 0;
}

main h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #C0C4D3;
  margin-bottom: 12px;
}

main.right-side .tab-content, main.right-side .tab-content .tab-pane{
  height: 100%;
}

main .head{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}

main .head .search-wrap{
  width: 480px;
  display: flex;
  border-radius: 12px;
  overflow: hidden;
}

main .head .search-wrap input{
  width: calc(100% - 143px);
  background: #222530;
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(192, 196, 211, 0.5);
  border: 0;
  border-radius: 0;
  padding: 11px 24px;
  outline: 0;
  font-family: 'Open Sans';
}

main .head .search-wrap button{
  width: 143px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #222530;
  text-align: center;
  background: linear-gradient(108deg,#30bd97 -60.82%,#6eb3d6 150.65%);
    border-radius: 5px;
  border-radius: 0px 12px 12px 0px;
  border: 0;
  transition: all ease-in-out 0.2s;
}

main .head .search-wrap button:hover{
  opacity: 0.9;
}

main .head .dropdown .dropdown-toggle{
  border: 0;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  padding-left: 20px;
}

main .head .dropdown .dropdown-toggle span{
  color: #00A669;
}

main .head .dropdown .dropdown-toggle::after{
  border-top: 5px solid #00A669;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  left: 0;
  top: 11px;
}


main .head .dropdown .dropdown-menu{
  background-color: #1B1E28;
  border-radius: 12px;
  transform: translate(0) !important;
  right: 0;
  min-width: 203px;
  left: auto !important;
  top: 25px !important;
  padding: 0;
  margin: 0;
  border: 0;
}

main .head .dropdown .dropdown-item{
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #C0C4D3;
  border-bottom: 1px solid #101218;
  padding: 10px 16px;
}

main .head .dropdown .dropdown-item:hover{
  background-color: transparent;
  color: #00A669;
}

main .head .dropdown .dropdown-item:last-child{
  border-bottom: 0;
}

.customer-table{
  overflow-x: auto;
  max-height: calc(100% - 106px);
  
}

.customer-table table{
  width: 2200px;
  border-collapse: inherit;
    border-spacing: 0 12px;
    margin-top: -16px;
}

.customer-table tr td:first-child,
.customer-table tr th:first-child{ 
    border-radius: 8px 0 0 8px;
}

.customer-table tr td:last-child,
.customer-table tr th:last-child{ 
    border-radius: 0 8px 8px 0;
}

.customer-table th{
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #B0B7C3;
  padding: 5px 20px;
  margin-bottom: 16px;
}

.customer-table tbody{
  background: #22282E;
}
::-webkit-scrollbar{
  width: 6px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 3px;
}
.customer-table tbody td{
  font-size: 15px;
  line-height: 22px;
  color: #B0B7C3;
  padding: 20px 20px;
  border-bottom: 1px solid #101218;
  font-weight: 500;
}

.customer-table tbody td.approved{
  color: #00A669;
}

.customer-table tbody td.not-approved{
  color: #FF941C;
}

.customer-table tbody td button{
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    padding: 4px 20px;
    border: 0;
}

.super-admin-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}

.super-admin-head h1{
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.super-admin-head .super-admin{
  display: flex;
  align-items: center;
}

.super-admin-head .super-admin span{
  color:  #B0B7C3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.super-admin-head .super-admin .img-wrap{
  padding: 2px;
  border-radius: 50%;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  margin-left: 16px;
}

.super-admin-head .super-admin img{
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.form-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.form-wrap .inner {
  width: 568px;
  max-width: 100%;
}
.form-wrap .inner .head{
  text-align: center;
  display: block;
}
.form-wrap .inner .head p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #A9AAAD;
  margin-top: 15px;
}
.form-wrap .inner .head h2{
  font-size: 20px;
  color: #fff;
}
.form-wrap label{
  display: block;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.form-wrap.min {
  min-height: calc(100vh - 137px);
}

.form-wrap form, .form-wrap .form-inner {
  background: #1D2833;
  border-radius: 5px;
  padding: 48px;
}

.form-wrap .form-group {
  position: relative;
  margin-bottom: 15px;
}

.form-wrap input,
.form-wrap textarea,
.form-wrap select {
  background: #3C3F47;
  border: 0;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A9AAAD;
  padding: 20px 35px;
  height: 70px;
  width: 100%;
  box-shadow: none;
  outline: 0;
}

.form-wrap button {
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  padding: 12px 15px;
  transition: all ease-in-out 0.2s;
  border: 0;
  box-shadow:none;
  outline: 0;
}

.form-wrap input:focus,
.form-wrap textarea:focus,
.form-wrap select:focus {
  border: 0.7px solid #55cbc6;
  --tw-ring-color: none;
}