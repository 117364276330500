/* main {
  max-width: calc(100% - 294px);
  margin-left: auto;
  height: 100vh;
  padding: 41px 52px 17px 0;
}

main h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #C0C4D3;
  margin-bottom: 12px;
}

main.right-side .tab-content, main.right-side .tab-content .tab-pane{
  height: 100%;
}

main .head{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}

main .head .search-wrap{
  width: 480px;
  display: flex;
  border-radius: 12px;
  overflow: hidden;
}

main .head .search-wrap input{
  width: calc(100% - 143px);
  background: #222530;
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(192, 196, 211, 0.5);
  border: 0;
  border-radius: 0;
  padding: 11px 24px;
  outline: 0;
  font-family: 'Open Sans';
}

main .head .search-wrap button{
  width: 143px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #222530;
  text-align: center;
  background: #00A669;
  border-radius: 0px 12px 12px 0px;
  border: 0;
  transition: all ease-in-out 0.2s;
}

main .head .search-wrap button:hover{
  opacity: 0.9;
}

main .head .dropdown .dropdown-toggle{
  border: 0;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  padding-left: 20px;
}

main .head .dropdown .dropdown-toggle span{
  color: #00A669;
}

main .head .dropdown .dropdown-toggle::after{
  border-top: 5px solid #00A669;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  left: 0;
  top: 11px;
}


main .head .dropdown .dropdown-menu{
  background-color: #1B1E28;
  border-radius: 12px;
  transform: translate(0) !important;
  right: 0;
  min-width: 203px;
  left: auto !important;
  top: 25px !important;
  padding: 0;
  margin: 0;
  border: 0;
}

main .head .dropdown .dropdown-item{
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #C0C4D3;
  border-bottom: 1px solid #101218;
  padding: 10px 16px;
}

main .head .dropdown .dropdown-item:hover{
  background-color: transparent;
  color: #00A669;
}

main .head .dropdown .dropdown-item:last-child{
  border-bottom: 0;
}

.customer-table{
  border-radius: 12px;
  overflow-x: auto;
  max-height: calc(100% - 106px);
}

.customer-table table{
  width: 2200px;
}

.customer-table th{
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  background: #00A669;
  padding: 17px 24px;
  margin-bottom: 16px;
}

.customer-table tbody{
  background: linear-gradient(90deg, #262936 0%, #1B1E27 100%);
}

.customer-table tbody td{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #C0C4D3;
  font-family: 'Open Sans';
  padding: 20px 24px;
  border-bottom: 1px solid #101218;
}

.customer-table tbody td.approved{
  color: #00A669;
}

.customer-table tbody td.not-approved{
  color: #FF941C;
} */
main {
  max-width: calc(100% - 322px);
  margin-left: auto;
  height: 100vh;
  padding: 32px 40px 17px 0;
}

main h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #C0C4D3;
  margin-bottom: 12px;
}

main.right-side .tab-content, main.right-side .tab-content .tab-pane{
  height: 100%;
}

main .head{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}

main .head .search-wrap{
  width: 480px;
  display: flex;
  border-radius: 12px;
  overflow: hidden;
}

main .head .search-wrap input{
  width: calc(100% - 143px);
  background: #222530;
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(192, 196, 211, 0.5);
  border: 0;
  border-radius: 0;
  padding: 11px 24px;
  outline: 0;
  font-family: 'Open Sans';
}

main .head .search-wrap button{
  width: 143px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #222530;
  text-align: center;
  background: linear-gradient(108deg,#30bd97 -60.82%,#6eb3d6 150.65%);
    border-radius: 5px;
  border-radius: 0px 12px 12px 0px;
  border: 0;
  transition: all ease-in-out 0.2s;
}

main .head .search-wrap button:hover{
  opacity: 0.9;
}

main .head .dropdown .dropdown-toggle{
  border: 0;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  padding-left: 20px;
}

main .head .dropdown .dropdown-toggle span{
  color: #00A669;
}

main .head .dropdown .dropdown-toggle::after{
  border-top: 5px solid #00A669;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  left: 0;
  top: 11px;
}


main .head .dropdown .dropdown-menu{
  background-color: #1B1E28;
  border-radius: 12px;
  transform: translate(0) !important;
  right: 0;
  min-width: 203px;
  left: auto !important;
  top: 25px !important;
  padding: 0;
  margin: 0;
  border: 0;
}

main .head .dropdown .dropdown-item{
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #C0C4D3;
  border-bottom: 1px solid #101218;
  padding: 10px 16px;
}

main .head .dropdown .dropdown-item:hover{
  background-color: transparent;
  color: #00A669;
}

main .head .dropdown .dropdown-item:last-child{
  border-bottom: 0;
}

.customer-table{
  overflow-x: auto;
  max-height: calc(100% - 106px);
  
}

.customer-table table{
  width: 2200px;
  border-collapse: inherit;
    border-spacing: 0 12px;
    margin-top: -16px;
}

.customer-table tr td:first-child,
.customer-table tr th:first-child{ 
    border-radius: 8px 0 0 8px;
}

.customer-table tr td:last-child,
.customer-table tr th:last-child{ 
    border-radius: 0 8px 8px 0;
}

.customer-table th{
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #B0B7C3;
  padding: 5px 20px;
  margin-bottom: 16px;
}

.customer-table tbody{
  background: #22282E;
}
::-webkit-scrollbar{
  width: 6px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 3px;
}
.customer-table tbody td{
  font-size: 15px;
  line-height: 22px;
  color: #B0B7C3;
  padding: 20px 20px;
  border-bottom: 1px solid #101218;
  font-weight: 500;
}

.customer-table tbody td.approved{
  color: #00A669;
}

.customer-table tbody td.not-approved{
  color: #FF941C;
}

.customer-table tbody td button{
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    padding: 4px 20px;
    border: 0;
}

.super-admin-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}

.super-admin-head h1{
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.super-admin-head .super-admin{
  display: flex;
  align-items: center;
}

.super-admin-head .super-admin span{
  color:  #B0B7C3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.super-admin-head .super-admin .img-wrap{
  padding: 2px;
  border-radius: 50%;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  margin-left: 16px;
}

.super-admin-head .super-admin img{
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.form-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.form-wrap .inner {
  width: 568px;
  max-width: 100%;
}
.form-wrap .inner .head{
  text-align: center;
  display: block;
}
.form-wrap .inner .head p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #A9AAAD;
  margin-top: 15px;
}
.form-wrap .inner .head h2{
  font-size: 20px;
  color: #fff;
}
.form-wrap label{
  display: block;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.form-wrap.min {
  min-height: calc(100vh - 137px);
}

.form-wrap form, .form-wrap .form-inner {
  background: #1D2833;
  border-radius: 5px;
  padding: 48px;
}

.form-wrap .form-group {
  position: relative;
  margin-bottom: 15px;
}

.form-wrap input,
.form-wrap textarea,
.form-wrap select {
  background: #3C3F47;
  border: 0;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A9AAAD;
  padding: 20px 35px;
  height: 70px;
  width: 100%;
  box-shadow: none;
  outline: 0;
}

.form-wrap button {
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  padding: 12px 15px;
  transition: all ease-in-out 0.2s;
  border: 0;
  box-shadow:none;
  outline: 0;
}

.form-wrap input:focus,
.form-wrap textarea:focus,
.form-wrap select:focus {
  border: 0.7px solid #55cbc6;
  --tw-ring-color: none;
}
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
}
.loader .loader-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader .loader-area .css-1mxr1bq,
.loader .loader-area .css-8vx899,
.loader .loader-area .css-anak8p {
  width: 80px;
  height: 80px;
}

.loader .loader-area  .loading-text {
  color: #888;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
}

.loader .loader-area .css-1i5m00m .css-m5syh7,
.loader .loader-area .css-1i5m00m .css-8q43r4 {
  background-color: #d82e35;
  height: 70px;
  width: 70px;
}

.loader .loader-area img{
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto 30px;
  animation: zoominoutsinglefeatured 1.5s infinite ;
}

@keyframes zoominoutsinglefeatured {
  0% {
      transform: scale(1,1);
  }
  50% {
      transform: scale(1.3,1.3);
  }
  100% {
      transform: scale(1,1);
  }
}
.sidebar-kyc {
    position: fixed;
    width: 290px;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #1D2833;
    padding: 36px 0;
    height: 100vh;
    overflow: auto;
}

.sidebar-kyc .logo {
    margin-bottom: 62px;
    margin-left: 24px;
    color: #fff;
    font-weight: 500;
}

.sidebar-kyc .logo img{
    margin-right: 0.75rem;
}

.sidebar-kyc .nav-tabs {
    border-bottom: none;
    flex-direction: column;
}

.sidebar-kyc .nav-tabs .nav-link {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    grid-gap: 17px;
    gap: 17px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #fff !important;
    height: 50px;
    padding: 8px 12px;
    margin-bottom: 10px;
    transition: all ease-in-out 0.2s;
    border-radius: 5px;
}
.sidebar-kyc .nav-tabs .nav-item{
    margin:0 12px;
}
.sidebar-kyc .nav-tabs .nav-item.show .nav-link,
.sidebar-kyc .nav-tabs .nav-link.active,
.sidebar-kyc .nav-tabs .nav-link:hover {
    background: rgba(255, 255, 255, 0.05);
}


.sidebar-kyc .user {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #C0C4D3;
    display: flex;
    align-items: center;
    grid-gap: 17px;
    gap: 17px;
    padding: 0 24px;
    position: absolute;
    bottom: 48px;
    left: 0;
    width: 100%;
}

.sidebar-kyc .user img {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}
.nav-bar .wallet-info {
    display: flex;
    align-items: center;
}

.nav-bar .wallet-info .login-role {
    position: absolute;
    right: 345px;
    font-size: 16px;
    font-weight: 600;
    color: #f9f9f9 !important;
}

.nav-bar .wallet-info .login-role span {
    font-weight: 500;
    text-transform: capitalize;
}


.nav-bar .wallet-info .address-btn {
    color: #f9f9f9;
    padding: 5px 15px;
    border-radius: 7px;
    background: #303342;
    border: 1px solid #f9f9f9;
}

.nav-bar .wallet-info .address-btn:hover {
    background: #f47a2f;
    color: #f9f9f9;
}

.nav-bar .wallet-info .balance-btn {
    position: absolute;
    right: 215px;
    z-index: -999;
    color: #f9f9f9;
    padding: 5px 20px;
    border-radius: 7px;
    background: #4f5057;
    border: 1px solid #f9f9f9;
}

.nav-bar .wallet-info .balance-btn:hover {
    background: #f47a2f;
    color: #f9f9f9;
}

.login-page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  background: #000c09;
  overflow: hidden;
}

.login-page .login-area {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 360px;
  position: relative;
  z-index: 99;
}
.login-page .login-area .login-form .login-title {
  color: #fff !important;
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.login-title .logoLogin {
  height: 39px;
  margin-right: 10px;
}
.login-page .login-area .login-form h1 {
  font-weight: 500;
  color: #fff;
  margin-bottom: 15px;
  font-size: 24px;
}
.login-page .login-area .login-form p {
  opacity: 0.7;
  margin-bottom: 20px;
  color: white !important;
}
.login-page .login-area .login-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background: #d82e35;
  background: linear-gradient(0deg, #d82e35 0%, #80181c 100%);
  background-size: 200% 200%;
  -webkit-animation: Animation 15s ease infinite;
  -moz-animation: Animation 15s ease infinite;
  animation: Animation 15s ease infinite;
  border-radius: 18px;
  padding: 50px 0px;
  position: relative;
  box-shadow: 0 0 20px rgb(0 0 0 / 30%); */
}

/* .login-page .login-area .login-form .login-title {
  color: #fff !important;
  text-align: center;
  font-size: 30px;
} */

.login-page .login-form .login-field {
  width: 100%;
  margin-bottom: 40px;
}

.login-page .login-form .validator-form {
  width: 100%;
  padding: 0 30px;
  text-align: center;
}
.login-page .login-form .MuiInputBase-root {
  color: #fff !important;
}
.login-page .login-form .validator-form label {
  color: #fff !important;
}

.login-page .login-form .validator-form .MuiFormHelperText-root.Mui-error {
  color: #fff !important;
}

.login-page .login-form .validator-form .MuiInput-underline:before,
.login-page .login-form .validator-form .MuiInput-underline:after {
  border-bottom: 1px solid #fff !important;
}

.login-page input:-webkit-autofill,
.login-page input:-webkit-autofill:hover,
.login-page input:-webkit-autofill:focus,
.login-page input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: #fff !important;
}

.login-page .login-area .login-form .validator-form .login-btn {
  width: 100%;
  border: none;
  padding: 14px 20px;
  color: #fff !important;
  font-size: 16px;
  min-height: 40px;
  line-height: 30px;
  font-weight: 700;
  border-radius: 6px;
  background: linear-gradient(108deg,#30bd97 -60.82%,#6eb3d6 150.65%);
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  text-transform: unset !important;
}
.MuiButton-label .xdc{
  margin-left: 10px;
}
.login-page .login-area .login-page-logo {
  width: 70%;
  padding: 60px 70px;
  border-radius: 20px;
  border: 2px solid #d82e35;
  background: #d82e35;
  box-shadow: rgba(242, 16, 16, 0.25) 0px 50px 100px -20px,
    rgba(175, 40, 40, 0.473) 0px 30px 60px -30px,
    rgba(245, 37, 37, 0.459) 0px -2px 6px 0px inset;
}

/* ========== Responsive =========== */

@media screen and (max-width: 425) {
  .login-page .login-area {
    margin-top: 15% !important;
  }
}
.bgLoginPg {
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.09;
  inset: 7px 4px 0px 0px;
  flex: 0 0 auto;
  mix-blend-mode: lighten;
  overflow: visible;
  z-index: 1;
  left: calc(49.59202175883955% - 1736px / 2);
  position: absolute;
  top: calc(51.164958061509815% - 1590px / 2);
  height: 1590px;
  left: calc(49.59202175883955% - 1736px / 2);
  position: absolute;
  top: calc(51.164958061509815% - 1590px / 2);
  width: 1736px;
}
.bgDashboard {
  background-image: url("/images/bgLogin.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.09;
  inset: 7px 4px 0px 0px;
  flex: 0 0 auto;
  mix-blend-mode: lighten;
  overflow: visible;
  z-index: 1;
  left: calc(49.59202175883955% - 1736px / 2);
  position: absolute;
  top: calc(51.164958061509815% - 1590px / 2);
  height: 1590px;
  left: calc(49.59202175883955% - 1736px / 2);
  position: absolute;
  top: calc(51.164958061509815% - 1590px / 2);
  width: 100%;
}


/* fonts css end */

/* global css start */
* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #161D23;
}

a {
  display: inline-block;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  padding: 0;
  list-style-type: none;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

/* global css end */
